import { useEffect, useState } from 'react'

const useDevice = () => {
  const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth < 1024)
  const [isDesktopView, setIsDesktopView] = useState<boolean>(window.innerWidth >= 1024)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024)
      setIsDesktopView(window.innerWidth >= 1024)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { isMobileView, isDesktopView }
}

export default useDevice
