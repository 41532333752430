// Work.tsx
import React, { useEffect, useRef, useState } from 'react'
import Experience from './Experience'

import { useInView } from 'react-intersection-observer'
import type { SwiperRef } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ReactComponent as IconLeft } from '../assets/images/icon_arrow_left_black.svg'
import { ReactComponent as IconRight } from '../assets/images/icon_arrow_right_black.svg'
import { experience } from '../data/experience'
import useDevice from '../hooks/useDevice'

const Work: React.FC = () => {
  const { isMobileView } = useDevice()

  const swipeRef = useRef<SwiperRef>(null)

  const [startAnimation, setStartAnimation] = useState(false)
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.7,
  })

  useEffect(() => {
    if (inView) {
      setStartAnimation(true)
    }
  }, [inView])

  const onClickPrev = () => {
    swipeRef.current?.swiper.slidePrev()
  }

  const onClickNext = () => {
    swipeRef.current?.swiper.slideNext()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      swipeRef.current?.swiper.slideNext()
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div ref={ref} className={`w-full ${isMobileView ? 'mt-10 px-4' : 'flex flex-row'}`}>
      <div
        className={`bg-[#00000045] rounded-lg opacity-0 sm:w-full xl:w-2/3 xl:max-w-[600px] xl:mt-20 ${startAnimation ? 'slide-right-to-left' : ''}`}
      >
        <section className='rounded-lg p-4'>
          <h2>Work Experience</h2>

          <div className='h-px border-b border-dotted' />

          <Swiper ref={swipeRef} slidesPerView={1} loop>
            {experience.map((exp, index) => (
              <SwiperSlide key={index}>
                <Experience
                  organisation={exp.organisation}
                  period={exp.period}
                  title={exp.title}
                  description={exp.description}
                  picture={exp.picture}
                  width={exp.width}
                  height={exp.height}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className='mt-3 flex flex-row items-center justify-center gap-4'>
            <IconLeft width={30} height={30} onClick={onClickPrev} className='cursor-pointer' />
            <IconRight width={30} height={30} onClick={onClickNext} className='cursor-pointer' />
          </div>
        </section>
      </div>

      {!isMobileView && (
        <div
          className={`h-52 w-full mr-40 border-r-2 border-b-2 border-white rounded-br-2xl opacity-0 ${startAnimation ? 'fade-in' : ''}`}
        />
      )}
    </div>
  )
}

export default Work
