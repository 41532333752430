import PropTypes from 'prop-types'

const Footer = (props: any) => (
  <footer id='footer' style={props.timeout ? { display: 'none' } : {}} className='mt-10'>
    <p className='copyright'>&copy; Reef Digital Services GmbH 2024 </p>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
