export type ProjectType = {
  id: number
  title: string
  period: string
  general: string
  delivery: string
}

export const projects: ProjectType[] = [
  {
    id: 1,
    title: 'Insurtech Project',
    period: 'ongoing',
    general:
      'Spearheaded the uplift of AWS architecture to align with the latest industry standard, ensuring a robust and scalable infrastructure. Collaborated closely with the local team to strategize and plan a comprehensive upgrade of their technology framework.',
    delivery:
      'Led the planning and execution of an offshore delivery team, overseeing the full onboarding process.\nConducted in-depth interviews to select and build a skilled team in Hanoi, Vietnam.\nSuccessfully onboarded the offshore team and established efficient ways-of-working, including comprehensive Software Development Life Cycle (SDLC) documentation, during an on-site assignment in Hanoi.',
  },
]
