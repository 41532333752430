// Disclosure.tsx
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import useDevice from '../hooks/useDevice'

const Disclosure: React.FC = () => {
  const { isMobileView } = useDevice()
  const [startAnimation, setStartAnimation] = useState(false)
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.7,
  })

  useEffect(() => {
    if (inView) {
      setStartAnimation(true)
    }
  }, [inView])

  return (
    <div ref={ref} className={`w-full ${isMobileView ? 'mt-10 px-4' : 'flex flex-row-reverse'}`}>
      <div
        className={`bg-[#00000045] rounded-lg opacity-0 sm:w-full xl:w-2/3 xl:max-w-[600px] xl:mt-20 ${startAnimation ? 'slide-left-to-right' : ''}`}
      >
        <section className='rounded-lg'>
          <h2>Disclosure</h2>
          <p>
            <b>Disclosures in accordance with § 5 TMG</b>
            <br />
            <br />
            <b>Address</b> <br />
            Reef Digital Services GmbH <br /> Friedrichstrasse 207 <br /> 10969 Berlin <br />
            thorsten@reef-digital.com
            <br />
            <br />
            <b>Commercial register entry</b>
            <br />
            Registry court: Amtsgericht Berlin (Charlottenburg) <br />
            Register number: HRB 256056 <br />
            Managing Director: Thorsten Maus <br />
          </p>
        </section>
      </div>

      {!isMobileView && (
        <div
          className={`h-60 w-full ml-40 border-l-2 border-b-2 border-white rounded-bl-lg opacity-0 ${startAnimation ? 'fade-in' : ''}`}
        />
      )}
    </div>
  )
}

export default Disclosure
