import React from 'react'
import { ProjectType } from '../data/projects'

type Props = {
  project: ProjectType
}

const Project: React.FC<Props> = ({ project }) => {
  return (
    <div className='mt-2'>
      <h3 className='underline'>{project.title}</h3>
      <div className='mb-1'>{project.period}</div>
      <p className='whitespace-pre-line'>
        <b>General:</b>
        <p>{project.general}</p>
        <b>Delevery:</b>
        <p>{project.delivery}</p>
      </p>
    </div>
  )
}

export default Project
