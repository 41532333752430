import logoReefDigital from '../assets/images/logo-reef-digital.png'

const Header = () => {
  return (
    <div className='w-full flex flex-col items-center'>
      <div className='flex items-center justify-center w-[86px] h-[86px] rounded-full border border-white'>
        <img src={logoReefDigital} alt='logo' />
      </div>
      <div className='h-14 w-[0.5px] bg-white' />

      <div className='w-full px-8 py-12 border-y border-white flex flex-col items-center text-center'>
        <div className='text-2xl xl:text-4xl font-semibold mb-4 tracking-[.5rem] leading-[1.3]'>
          REEF DIGITAL SERVICES GMBH
        </div>
        <div className='text-[10px] xl:text-[.8rem] tracking-[.2rem] leading-[2]'>
          STARTUP INCUBATION | TECHNICAL ADVISORY | FRACTIONAL CTO | OFFSHORING
        </div>
      </div>
    </div>
  )
}

export default Header
