import Ant from '../assets/images/ant.jpg'
import FilesappImage from '../assets/images/filesapp.jpg'
import HelloPay from '../assets/images/hellopay.jpg'
import Lazada from '../assets/images/lazada.jpg'
import LicusImage from '../assets/images/licus.jpg'
import Railsbank from '../assets/images/railsbank.jpg'
import ScVentures from '../assets/images/scventures.jpg'

export const experience = [
  {
    organisation: 'Filesapp/U-Know',
    title: 'Filesapp',
    period: '08/23-ongoing',
    description:
      'Developed and implemented a comprehensive go-to-market strategy, alongside a robust technology and delivery roadmap, for a B2B startup, aligning product capabilities with market demands to facilitate successful market entry and growth.',
    picture: FilesappImage,
    width: 250,
    height: 186,
  },
  {
    organisation: 'Licus',
    title: 'Licus',
    period: '06/23 - ongoing',
    description:
      'Designed a comprehensive technology stack and infrastructure migration strategy, currently overseeing its execution to ensure a seamless transition and optimal performance alignment with strategic business objectives.',
    picture: LicusImage,
    width: 250,
    height: 240,
  },
  {
    organisation: 'Standard Chartered Ventures',
    title: 'Technical Venture Lead',
    period: '09/22-12/24',
    description:
      'Served as a mentor to the business owners of two early-stage startups in the sustainability sector, taking on the responsibility for overseeing technology strategy and ensuring successful delivery of tech-driven solutions.',
    picture: ScVentures,
    width: 250,
    height: 250,
  },
  {
    organisation: 'Railsbank',
    title: 'Director of Engineering / Operations',
    period: '05/19-02/21',
    description:
      "As Director of Engineering and Co-COO at Railsbank Ltd, Singapore, I was instrumental in driving the company's growth in the APAC region. I co-owned ARR/NDR targets, integrating Australia and Singapore customer journeys into global processes, significantly streamlined the KYB onboarding by reducing turnaround time, and managed partnerships with banks. I also spearheaded the design and implementation of internal tools for Treasury/Ops, set up a new Kuala Lumpur office for 26 employees, and was part of the Global Executive Team, mapping the APAC strategy and overseeing the development of region-specific features, demonstrating my leadership in fostering regional growth and operational efficiency.",
    picture: Railsbank,
    width: 250,
    height: 132,
  },
  {
    organisation: 'CXA Group',
    title: 'Group CTO',
    period: '03/19-02/21',
    description:
      'In my leadership role, I engaged with the board and clients, guiding strategic interactions and fostering significant relationships. I expanded our Singapore engineering team from 15 to 120, spreading our talent across Singapore, Saigon, and Shanghai, and established a tech hub in Saigon, peaking at 76 employees, while also managing senior hires in Shanghai. I oversaw the transition from a legacy platform to a microservices architecture, moving from cloud agnostic to AWS, enhancing scalability and efficiency. Additionally, I developed an IT management layer for overseeing multiple client projects and transitioned the team to agile SDLC, mentoring leadership towards embracing a startup mentality.',
    picture: ScVentures,
    width: 250,
    height: 250,
  },
  {
    organisation: 'Ant Financial Group',
    title: 'CTO SEA / Senior Business Operations Manager',
    period: '11/16-03/19',
    description:
      "As the Senior Business Operations Manager at Ant Financial from January 2018 to March 2019 in Singapore, I played a pivotal role in overseeing and enhancing Lazada-related payment success-rate projects, focusing on process optimization to improve transaction success rates and customer experience. Before that, as a CTO SEA from November 2016 to December 2017, I spearheaded the migration of the Lazada/helloPay legacy payment platform to Ant Financial's iPay ePayment solution, overseeing a seamless transition that significantly enhanced platform efficiency and scalability, aligning with strategic business objectives.",
    picture: Ant,
    width: 250,
    height: 250,
  },
  {
    organisation: 'HelloPay',
    title: 'CTO',
    period: '01/16-11/16',
    description:
      "As the Chief Technology Officer (CTO) at helloPay, part of the Lazada Group, from January 2016 to April 2017 in Singapore, I led pivotal initiatives that significantly shaped the company's trajectory in the competitive payments market of Southeast Asia. I directed the establishment of our headquarters in Vietnam, scaling the team to 50 dedicated employees. Under my leadership, we successfully launched the helloPay platform in Singapore, Hong Kong, and Malaysia, marking a critical expansion phase. Furthermore, helloPay served as the payment provider across all Lazada countries, demonstrating our capability to deliver comprehensive, secure payment solutions that catered to the diverse needs of the region's e-commerce ecosystem. This strategic rollout not only enhanced our operational footprint but also solidified our position as a key player in the digital payments landscape.",
    picture: HelloPay,
    width: 250,
    height: 250,
  },
  {
    organisation: 'Lazada Group',
    title: 'Co-CTO',
    period: '03/14-01/16',
    description:
      "In my tenure as Co-CTO at Lazada Group from March 2014 to January 2016 in Singapore, I was instrumental in establishing and expanding the headquarters in Vietnam, growing the team to 180 engineers. My leadership was pivotal in driving the platform's growth and ensuring its stability, key factors in Lazada's market dominance. Additionally, I played a critical role in the inception of Lazada Express, laying the foundation for what would become a cornerstone of the company's logistics and delivery services, further solidifying Lazada's position in the e-commerce landscape.",
    picture: Lazada,
    width: 250,
    height: 250,
  },
]
