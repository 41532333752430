// Subsection.tsx

import React from 'react'
import useDevice from '../hooks/useDevice'

interface ExperienceProps {
  organisation: string
  period: string
  title: string
  description: string
  picture: string
  width: number
  height: number
}

const Experience: React.FC<ExperienceProps> = ({
  organisation,
  period,
  title,
  description,
  picture,
  width,
  height,
}) => {
  const { isMobileView } = useDevice()

  return (
    <div className='mt-2 flex gap-4 sm:flex-col xl:flex-row'>
      <div>
        <h3 className='underline'>{title}</h3>
        <div className='mb-1'>{period}</div>
        <p>
          {!isMobileView && (
            <img
              src={picture}
              alt={title}
              style={{
                maxWidth: width,
                maxHeight: height,
                objectFit: 'contain',
                float: 'right',
                borderRadius: '8px',
              }}
            />
          )}
          {description}
        </p>
      </div>
      {isMobileView && (
        <div style={{ maxWidth: width, maxHeight: height, objectFit: 'contain' }}>
          <img src={picture} alt={title} />
        </div>
      )}
    </div>
  )
}

export default Experience
