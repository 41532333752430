// Intro.tsx
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import ThorstenImg from '../assets/images/thorsten.jpg'

const Intro: React.FC = () => {
  const [startAnimation, setStartAnimation] = useState(false)
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  useEffect(() => {
    if (inView) {
      setStartAnimation(true)
    }
  }, [inView])

  return (
    <div ref={ref} className='w-full mt-20 sm:px-4 xl:px-0'>
      <div
        className={`bg-[#00000045] rounded-lg opacity-0 sm:w-full xl:w-2/3 xl:max-w-[600px] ${startAnimation ? 'slide-right-to-left' : ''}`}
      >
        <section className='rounded-lg'>
          <h2>Introduction</h2>
          <p>
            <img
              src={ThorstenImg}
              alt='Thorsten'
              className='float-left mr-4 sm:w-1/2 sm:max-w-32 sm:min-w-24 xl:w-1/3'
            />
            With 24 years of executive experience, I efficiently deliver complex technical projects.
            Known for an entrepreneurial approach and deep technical acumen, I excel in team
            motivation. As a recent Venture Builder at Standard Chartered Ventures, I served as
            interim CTO, overseeing SDLC, agile methodologies, and team management. Relocating from
            Berlin to Singapore in 2012, I've consistently demonstrated leadership and innovation.
          </p>
        </section>
      </div>
    </div>
  )
}

export default Intro
